import { createStore } from 'vuex'
import getters from './getters'

// 使用node的require的context 方法读取当前文件下，modules文件夹下面的所有JS文件
const files = require.context('./modules', false, /\.js$/)
const modules = {}

// 遍历文件模块，然后把内容填充到modules对象里面
files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
// 为modules 对象添加名字空间
Object.keys(modules).forEach((key) => {
  modules[key].namespaced = true
})

const store = createStore({
  state: {
    itemMoney: '',
    itemList: [],
    fileNum: 0,
    userPhone: '',
    userId: '', // 用户id
    creditState: '', // 授信状态
    backResult: {}
  },
  mutations: {
    SET_SEARCH_DATA: (state, data) => {
      state.backResult = data
    }

  },
  actions: {
    GetSearchData ({ commit }, obj) {
      commit('SET_SEARCH_DATA', obj)
    }
  },
  modules,
  getters
})

export default store
