import { createApp } from 'vue'
import APP from './App.vue'
import Vant from 'vant'
import router from '@/router'
import store from '@/store'
import filters from '@/filters'
// import mixin from '@/mixin/reload'
import './registerServiceWorker'
import './assets/normalize.css'
import './assets/layout.less'
import 'vant/lib/index.css'

const app = createApp(APP)
// app.mixin(mixin)
app.use(store).use(router).use(Vant).mount('#app')

// 定义全局指令
app.directive('focus', { mounted: el => el.focus() })

// 全局属性
app.config.globalProperties.$filters = filters
