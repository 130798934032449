const state = {
    title: '',
    hideNavBar:true,
    showLeftBack:true
  }
  const getters = {
    title: (state) => state.title,
    hideNavBar: (state) => state.hideNavBar,
    showLeftBack: (state) => state.showLeftBack,
  }
  const mutations = {
    setTitle (state, title) {
      state.title = title
    },
    setNavBar (state, visible) {
      state.hideNavBar = visible
    },
    setLeftBack (state, visible) {
      state.showLeftBack = visible
    },
  }
  const actions = {
    setTitle ({ commit }, title) {
      commit('setTitle', title)
    },
    setNavBar ({ commit }, visible) {
      commit('setNavBar', visible)
    },
    setLeftBack ({ commit }, visible) {
      commit('setLeftBack', visible)
    },
  }
  export default { state, getters, mutations, actions }
  